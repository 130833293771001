import React from 'react';
import * as P from './parts';
import {Container} from "styled-bootstrap-grid";
import {Trans} from "react-i18next";

const Section404 = () => {
    return (
        <P.StyledSection>
            <Container>
                <P.Content>
                    <P.StyledSuptitle>
                        <Trans>this_page_could_not_be_found</Trans>
                    </P.StyledSuptitle>
                    <P.StyledTitle>404</P.StyledTitle>
                </P.Content>
            </Container>
        </P.StyledSection>
    )
}

export default Section404;