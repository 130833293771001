import * as React from "react";
import Page from "../components/Page";
import SEO from "../components/utils/SEO";
import {graphql} from "gatsby";
import {Copyright, Footer, Nav} from "../components/partials";
import {Section404} from "../components/404";

const NotFoundPage = () => (
  <Page>
      <SEO title="404" />
      <Nav/>
      <Section404 />
      <Footer />
      <Copyright/>
  </Page>
)

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;