import styled from "styled-components";
import {Section} from "../../partials";
import {colors, fonts} from "../../../config";

export const StyledSection = styled(Section)`
  padding: 100px 0 0;

  @media screen and (min-width: 576px) {
    padding: 120px 0 0;
  }

  @media screen and (min-width: 992px) {
    padding: 140px 0 0;
  }
`;

export const Content = styled.div`
  padding: 15vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledSuptitle = styled.h3`
  text-align: center;
  font-size: 40px;
  line-height: 1.67;
  max-width: 95%;
  margin: 0 auto;
  
  @media screen and (max-width: 992px) {
    font-size: 36px;
  }

  @media screen and (max-width: 576px) {
    font-size: 32px;
  }
`;

export const StyledTitle = styled.h1`
  color: ${colors.white};
  font-size: 170px;
  font-weight: 700;
  line-height: 1.6;
  font-family: ${fonts.roboto};
  text-align: center;

  @media screen and (max-width: 992px) {
    font-size: 120px;
  }

  @media screen and (max-width: 576px) {
    font-size: 100px;
  }
`;